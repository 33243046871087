<template>
  <div>
    <AppHeader title="实验室监控"/>
    <Panel class="tabs monitor" :title="false">
      <div slot="content">
        <div class="line" v-for="(equipment,idx) of equipments">
          <div style="font-size: 14px; font-weight: 400;display: flex" @click="showOrHide(equipment)">
            <div class="flex-item-auto">{{ equipment.text }}</div>
            <div style="font-weight: bold;"><i class="iconfont icon-next" style="padding: 0 10px"></i></div>
          </div>
          <div v-if="showId === equipment.id">
            <div class="grid gap10">

              <div class="grid-cell-1-4" v-for="(sensor,idx) of sensors" v-if="!sensor.hidden">

                <div class="sensor" >
                  <div :class="`sensor-value ${sensor.statusClass}`" style="white-space: nowrap;overflow: hidden">{{ sensor.valueText }}</div>
                  <div class="sensor-title">{{ sensor.title }}</div>
                </div>
              </div>
            </div>
            <div class="grid gap10" v-for="(video,idx) of videos">
              <div class="grid-cell-1-2 video-location">
                {{ video.videoName }}
              </div>
              <div class="grid-cell-1-4">
                <div class="video live" @click="showLive(equipment.id, video.videoCode)">
                  <i class="iconfont icon-live"></i>
                </div>
              </div>
              <div class="grid-cell-1-4">
                <div class="video pc" @click="showScreen(equipment.id)">
                  <i class="iconfont icon-monitor"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>

    <mt-popup
        v-model="popupVisible"
        style="top:200px"
    >
      <video
          id="video"
          preload="auto"
          muted
          class="video-js vjs-default-skin"
          style="width: 100%; height: auto; object-fit: fill"
      >
        <source type="application/vnd.apple.mpegurl" ref="myVideo"/>
      </video>
    </mt-popup>
  </div>
</template>

<style lang="scss">
.monitor {
  .line {
    padding: 10px 15px;
  }
}

.video-location {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.sensor {
  overflow: hidden;
  text-align: center;
  border: 1px solid #eee;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09) !important;
  border-radius: 4px;
}

.sensor-value {
  font-weight: bold;

  &.yellow {
    color: #F6BB42;
  }

  &.green {
    color: #37BC9B;
  }

  &.red {
    color: #DA4453;
  }
}

.video {
  background: RGBA(124, 138, 202, .9);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;

  .iconfont {
    font-size: 22px !important;
  }
}
</style>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {mapState} from 'vuex';
import {Indicator, Popup} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import "@videojs/http-streaming"
import videojs from 'video.js'

export default {
  components: {
    AppHeader,
    Panel,
    Popup
  },
  data() {
    return {
      showId: null,
      videos: [],
      sensors: [],
      popupVisible: false,
      player: null
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    equipments(state) {
      return state.equipments
    }
  }),
  methods: {
    showOrHide(equipment) {
      const that = this
      if (this.showId === equipment.id) {
        this.showId = ''
      } else {
        that.sensors = []
        that.videos = []
        this.$http.post(`/portal/videos/initEquipVideos?equipmentId=${equipment.id}`).then(res => {
          that.videos = res.data
        })
        this.$http.post(`/portal/equipment/refreshStatus?id=${equipment.id}`).then(res => {
          that.sensors = res.map(s => {
            if (s.statusText === 2) {
              s.statusClass = 'yellow'
            } else if (s.statusText > 2 && s.statusText !== '水箱' && s.statusText !== '抽湿机') {
              s.statusClass = 'red'
            } else {
              s.statusClass = 'green'
            }
            return s
          })
        })
        this.showId = equipment.id
      }
    },
    showLive(equipmentId, videoCode) {
      const that = this
      this.$http.post(`/portal/ffmpegTask/start/stream?ffmpegType=real_time&equipmentId=${equipmentId}&videoCode=${videoCode}`, {indicator: false}).then(res => {
        if (res.success) {
          const videoUrl = `${that.hlsUrl}/${videoCode}/index.m3u8`
          this.waitAndPlay(videoUrl, "视频转换中...", "视频转换中或转换失败，请稍后再试", 9)
        }
      })
    },
    showScreen(equipmentId) {
      const videoUrl = `${this.hlsUrl}/${equipmentId}/index.m3u8`
      this.waitAndPlay(videoUrl, "查找客户端录屏文件...", "未找到客户端录屏文件", 1)
    },
    waitAndPlay(videoUrl, waitMsg, errorMsg , retryCnt){
      const that = this
      Indicator.open(waitMsg)
      that.waitUntilSuccess(videoUrl, 500, retryCnt, function () {
        Indicator.close()
        that.popupVisible = true
        that.$nextTick(function () {
          that.playVideo(videoUrl)
        })
      }, function () {
        Indicator.close()
        Toast(errorMsg)
      })
    },
    waitUntilSuccess(url, delay, retryCnt, successFun, errorFun) {
      const that = this
      let cnt = 0

      function doWait() {
        setTimeout(function () {
          that.$http.get(url, {autoToast: false, indicator: false}).then(res => {
            console.log(res)
            if (res.success === undefined) {
              //m3u8 ok !
              successFun()
            } else {
              cnt++
              if (cnt <= retryCnt) {
                doWait()
              } else {
                errorFun()
              }
            }
          })
        }, delay)
      }
      doWait();
    },
    playVideo(url) {
      const that = this
      that.player.src({
        src: url,
        type: 'application/vnd.apple.mpegurl'
      })
      // that.player.load()
      setTimeout(function (){
        that.player.play()
      },500)

    },
  },
  mounted() {
    this.$nextTick(function (){
      this.player =  videojs('video', {
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        controls: true,
        hls: {
          withCredentials: true
        }
      }, function () {

      })
    })
  }
}
</script>
